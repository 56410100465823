$primary: #e10600;
@import "~bootstrap/scss/bootstrap";

#root {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: 0;
  background: #fafafa;
}

#root {
  display: flex;
  flex-direction: column;
}



.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1
}

p {
  text-align: center;
}

.bold {
  font-weight: bold;
}